export default {
    data,
    created,
    computed: getComputed(),
    watch: getWatchers(),
    methods: getMethods(),
};

function created(){
    this.populateBuckets();
}

function data(){
    return {
        buckets: [],
        isSaving: false,
        errorMessages: [],
        editValues: {},
    };
}

function getComputed(){
    return {
        primaryCheckingAccount,
        loadingBankAccounts,
        remainingPercentage,
        canContinue,
    };

    function primaryCheckingAccount(){
        return this.$store.state.authorized.bankAccounts.bankAccounts
            .filter(({ slug }) => slug === 'primary_checking')[0];
    }

    function loadingBankAccounts(){
        const vm = this;
        return vm.$store.state.authorized.bankAccounts.isFetchingBankAccounts;
    }

    function canContinue(){
        const bucketsAvailable = true;
        return this.primaryCheckingAccount && bucketsAvailable;
    }

    function remainingPercentage(){
        const vm = this;
        const totalPercentage = vm.buckets.reduce((total, bucket) => total + (parseFloat(vm.editValues[bucket.id]) || 0), 0);
        return 100 - totalPercentage;
    }
}

function getWatchers(){
    return {
        loadingBankAccounts(newStatus, oldStatus){
            if(oldStatus && !newStatus){
                this.populateBuckets();
            }
        },
    };
}

function getMethods(){
    return {
        populateBuckets,
        saveAndContinue,
        validatePercentage,
    };

    function populateBuckets(){
        const vm = this;
        if(!vm.primaryCheckingAccount || vm.buckets.length > 0){
            return;
        }

        const newEditValues = {};
        vm.buckets = vm.primaryCheckingAccount.sub_accounts
            .filter(({ slug }) => slug !== 'income_deposit' && slug !== 'cc_payoff')
            .map(setDisplayProperties);
        vm.editValues = newEditValues;

        function setDisplayProperties(bucket, bucketOrder){
            const copyBucket = JSON.parse(JSON.stringify(bucket));
            const defaultPercentage = getBucketDefaultPercentage(bucketOrder);
            copyBucket.percentage = defaultPercentage;
            newEditValues[copyBucket.id] = defaultPercentage;
            return copyBucket;
        }

        function getBucketDefaultPercentage(order){
            switch (order){
                case 1:
                    return 25;
                case 2:
                    return 5;
                case 3:
                    return 3;
                case 4:
                    return 17;
                default:
                    return 50;
            }
        }
    }

    function validatePercentage(bucket, event){
        const vm = this;
        const remainingPercentage = vm.remainingPercentage;
        const currentValue = parseFloat(event.target.value) || 0;
        if(remainingPercentage < 0){
            vm.editValues[bucket.id] = Math.max(0, currentValue + remainingPercentage).toFixed(0);
        } else {
            vm.editValues[bucket.id] = currentValue.toFixed(0);
        }
    }

    function saveAndContinue(){
        const vm = this;
        const incomeAccount = vm.primaryCheckingAccount.sub_accounts.filter(({ slug }) => slug === 'income_deposit')[0];
        const payload = [];
        const allocatePercentages = vm.buckets.reduce((json, bucket) => ({
            ...json,
            [bucket.id]: bucket.percentage || 0,
        }), {});
        const allocateModes = vm.buckets.reduce((json, bucket) => ({
            ...json,
            [bucket.id]: 'percentage',
        }), {});

        if(!incomeAccount || vm.isSaving){
            return;
        }

        vm.buckets.forEach(moveMoneyToBucket);
        const hasAnyAllocationToMake = payload.length;
        if(!hasAnyAllocationToMake){
            return;
        }
        const organizePreferencePayload = {
            allocate_percentage: allocatePercentages,
            allocate_mode: allocateModes,
            schedule_period: 'Twice a month',
        };

        vm.isSaving = true;
        const allocateFunds = Vue.appApi().authorized().bankAccount().allocateFunds(incomeAccount.id, payload);
        const storeOrganizePreference = Vue.appApi().authorized().account().organizePreference().storeOrganizePreference(organizePreferencePayload);

        return Promise.all([allocateFunds, storeOrganizePreference])
            .then(handleAllocationSuccess)
            .catch(updateBucketFailure)
            .finally(resetLoadingState);

        function moveMoneyToBucket(bankAccount){
            const allocateFinalAmount = (vm.editValues[bankAccount.id] * incomeAccount.balance_available / 100).toFixed(2);
            const hasAmountToAllocate = allocateFinalAmount > 0;
            if(hasAmountToAllocate){
                payload.push({
                    bankAccountId: bankAccount.id,
                    amount: allocateFinalAmount
                });
            }
        }

        function updateBucketFailure(error){
            const errorMessage = error.appMessage || (error.data && error.data.message);
            if(errorMessage){
                vm.errorMessages.push(errorMessage);
            }
        }

        function handleAllocationSuccess(){
            vm.$store.dispatch('authorized/bankAccounts/FETCH_BANK_ACCOUNTS');
            vm.$router.push({ 'name': 'onboarding-success' });
        }

        function resetLoadingState(){
            vm.isSaving = false;
        }
    }
}
